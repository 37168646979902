import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import ApiService from '@/core/services/ApiService';
import BillCreateRow from '@/models/bill/BillCreatedRow';
import FiltersBillCreated from '@/models/bill/filters/FiltersBillCreated';
import FiltersSalesToBill from '@/models/bill/filters/FiltersSalesToBill';
import NewBill from "@/models/bill/NewBill";
import NewBillRequest from '@/models/bill/NewBillRequest';
import NewBillResponse from '@/models/bill/NewBillResponse';
import SalesToBillRow from '@/models/bill/SalesToBillRow';
import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import { AxiosResponse } from "axios";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import TableBillCreatedService from './interfaces/TableBillCreatedService';
import TableSalesToBillService from './interfaces/TableSalesToBillService';
import FiltersBillGlobal from '@/models/bill/filters/FilterBillGlobal';
import GlobalBillInfo from '@/models/bill/GlobalBillInfo';

export default class BillService implements GeneralService, TableSalesToBillService, TableBillCreatedService {
    
    apiController = "/Invoice";
    messageService: MessageService = new SwalMessageService();

    async saveGlobalBill(form: NewBillRequest): Promise<AxiosResponse<NewBillResponse>> {
        return ApiService.post<NewBillResponse>(this.apiController+"/CreateListAsyc", form)
        .then((resp) => {
            if(resp.status > 200 && resp.status < 500){
                this.messageService.success(translate('MESSAGES.SAVE_CORRECT'));
            }
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService, 'MessageError');
            return { data: null} as AxiosResponse;
        });
    }
    async saveBillGeneral(form: NewBillRequest): Promise<AxiosResponse<NewBillResponse>> {
        return ApiService.post<NewBillResponse>(this.apiController, form)
        .then((resp) => {
            if(resp.status > 200 && resp.status < 500){
                this.messageService.success(translate('MESSAGES.SAVE_CORRECT'));
            }
            /**ESTA RESPUESTA TRAE MUCHOS DATOS....
             * 
             * POR MIENTRAS SOLO RECUPERAMOS EL ID DE LA FACTURA CREADA EN EL MODELO NewBillResponse
             */
            return resp;
        }).catch((error) => {
            getMessageError(error, this.messageService, 'MessageError');
            return { data: null} as AxiosResponse;
        });
    }



    async searchTableInfo(pagination: PaginationRequest, filters: FiltersSalesToBill)
        : Promise<AxiosResponse<PaginationResponse<SalesToBillRow>>> {
        return  ApiService.get<PaginationResponse<SalesToBillRow>>(this.apiController, 
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.clientId && filters.clientId != '' ? `&Criteria.ClientId=${filters.clientId}` : '')
            + (filters.porcentage && filters.porcentage != '' ? `&Criteria.Porcentage=${filters.porcentage}` : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.StartDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.EndDate=${filters.endDate}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async searchTableBillCreated(pagination: PaginationRequest, filters: FiltersBillCreated) 
        :Promise<AxiosResponse<PaginationResponse<BillCreateRow>>> {
        return  ApiService.get<PaginationResponse<BillCreateRow>>(this.apiController, 
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.clientName && filters.clientName != '' ? `&Criteria.ReciverName=${filters.clientName}` : '')
            + (filters.folio && filters.folio != '' ? `&Criteria.Folio=${filters.folio}` : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.InitialDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.EndDate=${filters.endDate}` : '')
            + (filters.rfc && filters.rfc != '' ? `&Criteria.ReciverRFC=${filters.rfc}` : '')
            + (filters.type != null ? `&Criteria.type=${filters.type}` : '')
            + (filters.saleFolio && filters.saleFolio != '' ? `&Criteria.SalesFolio=${filters.saleFolio}` : '')
            + (filters.isSend != null ? `&Criteria.IsSend=${filters.isSend}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Obtiene la cadena base64 que representa la factura en documento pdf
     * @param billId Identificador de la factura
     */
    async getBillFile(billId: string) 
        :Promise<AxiosResponse<string>> {

        return  ApiService.get<string>(this.apiController, 
            `GetFile?`//puede cambiar esta ruta
            + `id=${billId}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchInfoBillGlobal(filters: FiltersBillGlobal): Promise<AxiosResponse<GlobalBillInfo>> {
         return  ApiService.get<GlobalBillInfo>(this.apiController, 
            `GetSalesInvoiceAsync?`//puede cambiar esta ruta
            + `fecha_inicial=${filters.startDate}`
            + `&fecha_Final=${filters.endDate}`
            + (filters.porcentage && filters.porcentage != '' ? `&porcentage=${filters.porcentage}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async stampBillGlobalAsync(request: FiltersBillGlobal): Promise<AxiosResponse<string>> {
         return  ApiService.get<GlobalBillInfo>(this.apiController, 
            `StampBillGlobalAsync?`//puede cambiar esta ruta
            + `fecha_inicial=${request.startDate}`
            + `&fecha_Final=${request.endDate}`
            + (request.porcentage && request.porcentage != '' ? `&porcentage=${request.porcentage}` : '')
         ).then(resp => {
             if (resp.data) {
                 this.messageService.success("Se genero correctamente la factura")
             } else { 
                 this.messageService.success("Hubo un error al momento de generar la factura")
             }
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}